import control_dialog_html from '../../../modules/administration/control_process/control_dialog/control_dialog.pug';
import add_bike_dialog_html from '../../../dialogs/add_bike_dialog/add_bike_dialog.pug';
import document_flow_dialog_html from '../../../modules/administration/control_process/documentFlowDialog/documentFlowDialog.pug';
import user_groups_administration_dialog_html from '../../../modules/cockpit/arbeitnehmerverwaltung/userGroupsAdministrationDialog.pug';
import change_employee_status_dialog_html from '../../../modules/cockpit/arbeitnehmerverwaltung/changeEmployeeStatusDialog.pug';
import change_employee_state_dialog_html from '../../../dialogs/change_employee_state/change_employee_state.pug';
import cancel_bo_digital_signing_request_dialog_html from '../../../dialogs/cancel_bo_digital_signing_request_dialog/index.pug';
import { TogglePcLeaseBlockingDialogComponent } from '../../../../app/administration/dialogs/neo/toggle-pc-lease-blocking-dialog/toggle-pc-lease-blocking-dialog.component';


import { DigitalSigningRequest, RequestState } from '../../../../app/back-office/models/digital-signing-request.model';


import {
  EMPLOYEE_STATUSES_FOR_FILTERS,
  NAME_EMPLOYEE_STATUSES_MAP,
  ID_EMPLOYEE_STATUSES_MAP,
  EMPLOYEE_STATUSES_GROUPS,
  ALL_CONTROL_STATUS_NAMES
} from '../../../services/employeeService';
import {SUPPORT_REQUEST_TAG} from '../../../services/SupportService';

import {
  BONUS_COMPONENTS_WITH_DOCUMENTS,
  NEO_COMPONENTS_WITH_DOCUMENTS,
  PROJECT_TAGS_BY_NAME,
  PROJECT_TAGS,
  COMPONENTS_WITH_DOCUMENTS_BY_ID,
  BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID
} from '../../../services/customerService';

import { EMPLOYEE_ADMINISTRATION_DEFAULT_FILTERS, APPROVAL_PROCESS_TYPES_BY_NAME } from '../../../services/administrationService';

import { DEFAULT_YEARLY_DOCUMENTS_TAG_STRING } from '../../../services/yearlyService';

import {
  DIGITAL_SIGNING_COORDINATES_REGEX,
  DIGITAL_SIGNING_DEFAULT_COMMENT
} from '../../../services/digital-signing/digital-signing.service';
import {DIGITAL_SIGNING_DEFAULT_COORDINATES} from '../../../services/digital-signing/digital-signing.service';

const $inject = [
  '$scope',
  '$q',
  '$window',
  '$state',
  '$http',
  '$timeout',
  'ngDialog',
  'authenticationService',
  'dialogService',
  'customerService',
  'advisorService',
  'employeeService',
  'userService',
  'DocumentConstants',
  'NotificationService',
  'UserNotificationService',
  'Made',
  'moment',
  'administrationService',
  'vbmData',
  'fileService',
  'UserGroupsService',
  'mailService',
  'FilenameService',
  'ACLService',
  'lodash',
  'VSP_CONSTANTS',
  'BackendFileUploadMapper',
  'DigitalSigningService',
  'MatDialogService',
  'DticketOrderService'
];
export default class EmployeeListController {

  constructor (
    $scope,
    $q,
    $window,
    $state,
    $http,
    $timeout,
    ngDialog,
    authenticationService,
    dialogService,
    customerService,
    advisorService,
    employeeService,
    userService,
    DocumentConstants,
    NotificationService,
    UserNotificationService,
    Made,
    moment,
    administrationService,
    vbmData,
    fileService,
    UserGroupsService,
    mailService,
    FilenameService,
    ACLService,
    lodash,
    VSP_CONSTANTS,
    BackendFileUploadMapper,
    DigitalSigningService,
    MatDialogService,
    DticketOrderService
  ) {

    Object.assign(this, {
      $scope,
      $q,
      $window,
      $state,
      $http,
      $timeout,
      ngDialog,
      dialogService,
      authenticationService,
      advisorService,
      customerService,
      employeeService,
      userService,
      DocumentConstants,
      NotificationService,
      UserNotificationService,
      Made,
      moment,
      administrationService,
      vbmData,
      fileService,
      UserGroupsService,
      mailService,
      FilenameService,
      ACLService,
      lodash,
      VSP_CONSTANTS,
      BackendFileUploadMapper,
      DigitalSigningService,
      MatDialogService,
      DticketOrderService
    });
    this.customerId = $state.params.customerId;
    this.iAmGAdvisor = authenticationService.iAm('gadvisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmEmployeeAdministrator = authenticationService.iAm('sa_employee_administration');
    this.lockedEmployees = [];
    this.query = '';
    this.control_statuses = ALL_CONTROL_STATUS_NAMES;
    this.employees = [];
    this.customers_by_id = {};
    this.pcBlockedEmployeesInitList = [];

    this.APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES_BY_NAME;

    //this can be refactored
    this.PROJECT_TAGS_BY_NAME = angular.copy(PROJECT_TAGS_BY_NAME);
    this.project_tags = PROJECT_TAGS.filter(tag => tag.value !== PROJECT_TAGS_BY_NAME['bonus'].value);
    this.components = this.initFileSubtypes();

    this.DIGITAL_SIGNING_COORDINATES_REGEX = angular.copy(DIGITAL_SIGNING_COORDINATES_REGEX);

    this.setDefaultFilters();
    this.getUserGroups();
    this.getACLUserGroups();

    this.forms = {
      pcLeasingBlock: {}
    };

    const search = this.$state.params;
    if (search && search.employeeId ) {
      this.employee_id = this.$state.params.employeeId;
      this.$scope.filters.id = search.employeeId;
      this.filterEmployees(true);
    }

  }

  openDocument(document){
    this.fileService.openDocument(document._id);
  }

  setDefaultFilters () {
    this.$scope.filters = angular.copy(EMPLOYEE_ADMINISTRATION_DEFAULT_FILTERS);
    //set additional neo-specific filters;
    this.$scope.filters['neo_status'] = {
      selected: [],
      options: [...EMPLOYEE_STATUSES_GROUPS, ...EMPLOYEE_STATUSES_FOR_FILTERS ]
    };
    this.$scope.filters['ev_begin'] = '';
    this.$scope.filters['search_all_customers'] = false;
  }

  addNeoBikeleasing(employeeData){
    this.dialogService.ngDialog.openConfirm({
      template: add_bike_dialog_html(),
      plain: true,
      controller: 'AddNeoBikeDialogController',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      data: {
        'employee': employeeData
      },
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    });
  }

  goToOTRS(employeeData){
    this.employeeService.goToOTRS(employeeData.id);
  }

  goToNorbert(employeeData){
    this.employeeService.goToNorbert(employeeData.id);
  }

  goToBonus(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.bonus',
      'params': {employeeId: employeeId}
    });
  }

  goToPCleasing(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.mpp.edit',
      'params': {employeeId: employeeId}
    });
  }

  startControlProcess (employee_id, customer_id) {
    this.dialogService.ngDialog.openConfirm({
      template: control_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'ControlProcessControlDialogController',
      data: {
        employee_id: employee_id,
        customer_id: customer_id
      },
      controllerAs: '$ctrl',
      width: 1400,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.filterEmployees();
    }).catch(() => {
      this.filterEmployees();
    }).finally(() => {
      return this.administrationService.unlockEmployee(employee_id).then((result) => {
        if (result.unlocked) {
          this.NotificationService.message('Mitarbeiter wurde entsperrt');
        }
      });
    });
  }

  startDocumentFlowProcess (employee_id, customer_id) {
    return this.dialogService.ngDialog.openConfirm({
      template: document_flow_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'ControlProcessDocumentFLowDialogController',
      data: {
        employee_id: employee_id,
        customer_id: customer_id
      },
      controllerAs: '$ctrl',
      width: 1100,
      closeByDocument: true,
      closeByEscape: false,
      closeByNavigation: true
    }).then(() => {
      this.filterEmployees();
    }).catch(() => {
      this.filterEmployees();
    }).finally(() => {
      return this.administrationService.unlockEmployee(employee_id).then((result) => {
        if (result.unlocked) {
          this.NotificationService.message('Mitarbeiter wurde entsperrt');
        }
      });
    });
  }

  getEmployee (employee_id) {
    this.loading = true;
    this.employeeService.get(employee_id).then((employee) => {
      this.employees = [employee];
    }).finally(() => {
      this.loading = false;
    });
  }

  areFiltersEmpty(filters) {
    let user_entered_filter_keys = [
      'id',
      'cust_id',
      'customer_name',
      'ev_begin',
      'personal_number',
      'name',
      'made_status',
      'yearly_state',
      'neo_status',
      'last_login',
      'order',
      'email'
    ];
    for (let key of user_entered_filter_keys) {
      let filter = this.lodash.get(filters, key, '');
      // last_login filter is an object
      if (key === 'last_login' && filter || filter.length > 0) {
        return false;
      }
    }

    return true;
  }


  triggerSearch(e) {
    if (e && e.keyCode === 13) {
      e.preventDefault();
      this.filterEmployees();
    }
  }

  filterEmployees ( toggle_content=false ) {
    let filters = this.getSelectedFilters();

    if (this.areFiltersEmpty(filters)){
      this.grouped_employees = [];
      this.employees = [];
      return ;
    }

    this.loading = true;
    this.employeeService.filterEmployees(filters)
      .then((employees) => {
        this.employeeIds = this.employeeService.getEmployeeIds(employees);
        this.employees = employees;
        this.employee_objects = {};
        this.employees.forEach( (employee) => { this.employee_objects[employee.id] = {}; });
        this.grouped_employees = this.employeeService.groupEmployeesByCustomerId(this.employees);
        this.employees = this.employeeService.parseEmployeeStatuses(this.employees);
        this.customers_by_id = this.employeeService.indexCustomers(this.employees);
      })
      .then(() => {
        return this.administrationService.getAllLockedEmployees(this.employeeIds).then(
          result => this.lockedEmployees = result
        );
      })
      .then(() => {
        return this.administrationService.getEmployeesWithPendingPCApproval(this.employeeIds).then(
          employees_with_pc => this.employeesWithPendingPCApprovals = employees_with_pc
        );
      })
      .finally(() => {
        this.$timeout(()=>{
          this.loading = false;
          if ( toggle_content ) {
            this.employee_objects[this.employee_id].toggleContentShowable = true;
            this.loadEmployeeData(this.employees[0], this.employee_objects[this.employee_id] , false);
          }

          // this.employees.forEach( async (employee) => {
          //   this.loadPcLeaseBlockingData(employee.id);
          // });
        });

      });
  }

  openGroupAdministrationDialog(event, employee_id, employee_data) {
    event.preventDefault();
    event.stopPropagation();
    this.dialogService.ngDialog.openConfirm({
      template: user_groups_administration_dialog_html(),
      plain: true,
      controller: 'UserGroupsAdministrationDialogController',
      controllerAs: '$ctrl',
      data: {
        employee_id: employee_id,
        employee_data: employee_data
      },
      width: 800,
      closeByDocument: false,
      closeByEscape: false,
      closeByNavigation: true
    }).then(() => this.loadEmployees());
  }

  getUserGroups() {
    return this.UserGroupsService.getAllUserGroups().then(
      (res) => {
        this.USER_GROUPS_BY_NAME = res.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
      }).catch((err) => {
      console.error(err);

    });
  }

  getSelectedFilters () {

    let filters = {
      id: this.$scope.filters.id,
      name: this.$scope.filters.name,
      personal_number: this.$scope.filters.personal_number,
      made_status: this.$scope.filters.made_status.selected.map(status => status.id),
      yearly_state: this.$scope.filters.yearly_state.selected.map(state => state.id),
      search_all_customers: this.$scope.filters.search_all_customers,
      order_by_name: this.$scope.filters.order_by_name == true ? 'DESC' : 'ASC',
      ev_begin: this.$scope.filters.ev_begin,
      enable_timestamp: false,
      email: this.$scope.filters.email,
      hide_testclients: this.$scope.filters.hide_testclients,
    };

    //check if the selected neo status filter is group
    let neo_statuses = [];
    this.$scope.filters.neo_status.selected.forEach((status) => {
      if (status.statuses) {
        filters.enable_timestamp = true;
        status.statuses.forEach((group_status) => {
          neo_statuses.push(group_status.name);
        });
      } else if( this.control_statuses.includes(status.name)){
        filters.enable_timestamp = true;
        neo_statuses.push(status.name);
      } else {
        neo_statuses.push(status.name);
      }
    });

    filters.neo_status = neo_statuses;

    //customer id/name logic
    let is_cust_id = !isNaN(this.$scope.filters.custId);
    if (is_cust_id) {
      filters.cust_id = this.$scope.filters.custId;
      filters.customer_name = '';
    } else {
      filters.customer_name = this.$scope.filters.custId;
      filters.cust_id = '';
    }

    if (this.$scope.filters.last_login.selected && this.$scope.filters.last_login.selected.length > 0) {
      let last_login_filter = {
        gt: undefined,
        lt: undefined
      };
      let date_ranges = [];
      let no_upper_limit = false;
      this.$scope.filters.last_login.selected.forEach((filter) => {
        date_ranges.push(this.moment().subtract(filter.months_range.lower_limit, 'months'));
        if (filter.months_range.upper_limit != false) {
          date_ranges.push(this.moment().subtract(filter.months_range.upper_limit, 'months'));
        } else {
          no_upper_limit = true;
        }
      });

      let ordered_dates = date_ranges.sort((a, b) => {
        return a.isSameOrAfter(b);
      });

      last_login_filter.lt = ordered_dates[0].toISOString();
      if (!no_upper_limit) {
        last_login_filter.gt = ordered_dates[ordered_dates.length - 1].toISOString();
      }

      filters.last_login = last_login_filter;
    }

    return filters;
  }

  loadAllEmployees () {
    this.loading = true;
    this.employeeService.filterEmployees().then((employees) => {
      this.employees = employees;
      this.parseEmployeeStatuses();
    }).finally(() => {
      this.loading = false;
    });
  }


  become(employeeData) {

    this.loading = true;
    const context_path = '/administration/neo?employeeId=' + employeeData.id;

    this.dialogService
      .question(`Achtung, der Nutzer wird über diesen Vorgang informiert.
        Wollen Sie wirklich in ${employeeData.name}, ${employeeData.vorname}
        (${employeeData.id}) schlüpfen?`)
      .then(() => this.userService.enterProxy(employeeData.id, context_path))
      .catch(() => this.loading = false);

  }

  cancelDigitalSigningBoRequest(file, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: cancel_bo_digital_signing_request_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'CancelBoRequestDialogController',
      data: {
        file: file
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(async (res) => {
      console.log("reload", res)
      if (res) {
        this.$timeout(()=>{
          this.loading = true;
        });

        this.boDigitalSigningRequests = await this.DigitalSigningService.getRequestsForSigning(file.meta.valuenet.employee_id);

        this.$timeout(()=>{
          this.loading = false;
        });

      }
    });
  }

  loadEmployeeData (employeeData, employee, toggle_content_showable) {
    if (toggle_content_showable) {
      return;
    }

    employee.documents_metadata = this.initDocumentTagsConfig();
    let promises = [];

    promises.push(
      this.DigitalSigningService.getRequestsForSigning(employeeData.id).then(res => {
        this.boDigitalSigningRequests = res
      })
    )


    promises.push(this.vbmData
      .getResult(employeeData.id)
      .then(()=>{employee.vbm = this.vbmData.employees[employeeData.id];})
      .catch(() => employee.error = true));

    promises.push(
      this.userService.getLastInviteDate(employeeData.id)
        .then( last_invite_date =>
          employee.invitation_date = last_invite_date ? this.moment.unix(last_invite_date).format('DD.MM.YYYY') : '-')
    );

    promises.push(this.userService.getMadeUserByValuenetId(employeeData.id, Date.now())
      .then(user => {

        employee.toc = user.refused ? user.refused : (user.toc ? this.moment.unix(user.toc[0]).format('DD.MM.YYYY') : '-');
        // employee.invitation_date = this.getInvitationDate(employeeData.id, employee);
        employee.email = user.emails[0];
        employee.last_login_date = employeeData.access_status ?  this.moment.unix(employeeData.access_status.lastLogin).format('DD.MM.YYYY') : '-';
        employee.created = user.created;
        employee.registration_type = user.selfregister === true ? 'Selbstregistrierung' : 'VN-Registrierung';
        employee.groups = user.groups;

        employee._id = user._id;
        employee.active = user.active;
        employee.valuenetId = employeeData.id

        if (this.authenticationService.iAm('employer', user)) {
          employee.roleEmployer = true;
        }

        if (this.authenticationService.iAm('gadvisor', user)) {
          employee.roleGAdvisor = true;
        }

        if (this.authenticationService.iAm('advisor', user)) {
          employee.roleAdvisor = true;
        }

        if (this.authenticationService.iAm('cms-editor', user)) {
          employee.roleCmsEditor = true;
        }

        return this.userService.getUploadedFiles(user._id);
      }, () => this.needMade = true)
      .then(files => this.setEmployeeCockpitFiles(employee, files)));

    if (this.iAmSpecialAdvisor) {
      promises.push(
        this.getContracts(employeeData.id, employee)
      );
    }

    promises.push(
      this.ACLService.getEmployeeCustomerSpecificGroups(employeeData.id)
        .then((customer_specific_groups) => {
          employee.customer_specific_groups = customer_specific_groups;
        })
    );

    promises.push(
      this.customerService.getConfigurationByEmployeeId(employeeData.id)
        .then((customer_configuration) => {
          employee.is_digital_signature_enabled = this.lodash.get(customer_configuration, 'digital_signiture_config.is_enabled');
          employee.documents_metadata = {
            request_digital_signing: false ,
            digital_signing_coordinates: angular.copy(DIGITAL_SIGNING_DEFAULT_COORDINATES),
            digital_signing_comment: angular.copy(DIGITAL_SIGNING_DEFAULT_COMMENT)
          };
        })
    );

    // promises.push(this.loadPcLeaseBlockingData(employeeData.id));

    return this.$q
      .all(promises)
      .finally(() => {
        this.$timeout(()=>{
          this.loading = false;
        });
      });
  }

  // TODO: CODE-DUPLICATION
  getContracts(employee_id, employee) {
    return this.employeeService.getContracts(employee_id)
      .then((res) => {
        employee.contracts = this.filterFinalizableContracts(res);
        employee.contracts.forEach((contract) => {
          if (contract.finalisierungsdatum) {
            contract['parsedFinalisationDate'] = this.moment.unix(contract.finalisierungsdatum).format('DD.MM.YYYY');
          }
        });
      });
  }

  toShowControlProcessStart (employeeData) {
    return employeeData._id && (
      employeeData.employee_status.name === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_d.name ||
      employeeData.employee_status.name === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_b.name ||
      employeeData.employee_status.name === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_s.name ||
      employeeData.employee_status.name === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_k.name);
  }

  goToDticket(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.dticket',
      'params': {employeeId: employeeId}
    });
  }

  filterFinalizableContracts (contracts) {
    let last_id;
    let last_finalized_contract_id;
    let filtered_contracts = [];
    let finalized_contracts_count = 0;
    if (contracts && contracts.length) {
      contracts.forEach((contract) => {
        if (contract.final == 'finalized') {
          last_id = contract.ai_id;

          if (finalized_contracts_count < 5) {
            filtered_contracts.push(contract);
            finalized_contracts_count += 1;
          } else {
            filtered_contracts.shift();
            filtered_contracts.push(contract);
          }
        }
      });

      if (last_id !== undefined) {
        contracts.forEach((contract) => {
          if (contract.ai_id > last_id && contract.final != 'finalized') {
            filtered_contracts.push(contract);
          }
        });
      } else {
        return contracts;
      }
    }

    return filtered_contracts;
  }


  toShowControlProcess(employeeData){
    return employeeData['_id'] && (
      employeeData.employee_status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_d.id ||
      employeeData.employee_status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_b.id ||
      employeeData.employee_status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_s.id ||
      employeeData.employee_status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_k.id );
  }

  changeNeoStatus (employeeData, $event) {
    $event.stopPropagation();
    $event.preventDefault();
    if (!employeeData._id ){
      this.NotificationService.error('Employee is not in MADE');
      return;
    }

    let employee_id = employeeData.id;
    let customer_id = employeeData.customer_id;
    this.loading = true;
    this.employeeService.getCurrentStatus(employee_id).then(status => {
      if ( status &&
        (status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_d.id ||
          status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_b.id ||
          status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_s.id ||
          status.id === NAME_EMPLOYEE_STATUSES_MAP.kontrolle_k.id)) {
        this.startControlProcess(employee_id, customer_id);
      } else if ( status &&
        (status.id === NAME_EMPLOYEE_STATUSES_MAP['kontrolle_t']['id'] ||
          status.id === NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p1']['id'] ||
          status.id === NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p2']['id'] ||
          status.id === NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p3']['id'] ||
          status.id === NAME_EMPLOYEE_STATUSES_MAP['kontrolle_p6']['id'])) {
        this.startDocumentFlowProcess(employee_id, customer_id);
      } else {
        this.dialogService.ngDialog.openConfirm({
          template: change_employee_status_dialog_html(),
          plain: true,
          data: {
            employee: employeeData,
            employee_access: employeeData.access_status
          },
          controller: 'ChangeEmployeeStatusDialogController',
          controllerAs: 'ChangeEmployeeStatusDialogCtrl',
          width: 800,
          closeByDocument: false,
          closeByEscape: false,
          closeByNavigation: true
        }).then((result) => {
          this.filterEmployees();
        });
      }
    }).finally(() => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    });
  }

  arbeitnehmerFreischalten (employee, force) {
    this.dialogService
      .employeeInvite(employee.id, force)
      .then(() => {
        this.filterEmployees();
      });
  }

  arbeitnehmerFixUser(employee) {
    this.$timeout(() => {
      this.loading = true;
    });
    this.customerService.fixUser(employee.customer_id, employee.id).then(
      () => {
        this.NotificationService.message('Der Mitarbeiter wurde erfolgreich freigeschaltet.');
      },
      () => {
        this.NotificationService.error('Leider konnte der Mitarbeiter nicht freigeschalten werden.');
      }).finally(() => {
        this.filterEmployees();
        this.loading = false;
    });
  }

  editEmployee (employee_id) {
    this.dialogService
      .employeeCreate(employee_id)
      .then(() => {
        this.filterEmployees();
      });
  }

  editContactDetails (employee) {
    this.dialogService
      .employeeContactDetails(employee)
      .then(() => {
        this.loadEmployees();
      });
  }

  deactivate (employee_id) {
    this.dialogService
      .question('Soll dieser Nutzer wirklich deaktiviert werden?')
      .then(() => {
        this.loading = true;
        this.userService
          .deactivate(employee_id)
          .then(() => {
            this.filterEmployees();
          });
      })
      .finally(() => this.loading = false);
  }

  applyContractFinalisation(employeeData, employee) {
    if (this.iAmSpecialAdvisor && employeeData.contract_to_be_updated) {
      if (employeeData.contract_to_be_updated.final === 'finalized') {
        this.finaliseContract(employeeData.contract_to_be_updated.ai_id, employeeData.contract_to_be_updated.calc_id, this.moment(), employeeData.id).then((res) => {
          this.employeeService.getContracts(employeeData.id).then((res) => {
            employee.contracts = this.filterFinalizableContracts(res);
          });
          employeeData.contract_to_be_updated = null;
        }).catch((err) => {

        });
      } else if (employeeData.contract_to_be_updated.final === null) {
        this.unfinaliseContract(employeeData.contract_to_be_updated.ai_id, employeeData.contract_to_be_updated.calc_id).then((res) => {
          this.employeeService.getContracts(employeeData.id).then((res) => {
            employee.contracts = this.filterFinalizableContracts(res);
          });
          employeeData.contract_to_be_updated = null;
        }).catch((err) => {

        });
      }
    }q;
  }

  finaliseContract (ai_id, calc_id, date, employee_id) {
    return this.employeeService.finaliseContract(ai_id, calc_id, date, employee_id);
  }

  unfinaliseContract (ai_id, calc_id) {
    return this.employeeService.unfinaliseContract(ai_id, calc_id);
  }

  markFinalisedContract(contract, employeeData, method) {
    if (!employeeData.contract_to_be_updated || (employeeData.contract_to_be_updated && employeeData.contract_to_be_updated.ai_id !== contract.ai_id)) {
      employeeData.contract_to_be_updated = angular.copy(contract);
      if (method === 'finalise') {
        employeeData.contract_to_be_updated.final = 'finalized';
      } else if (method === 'unfinalise') {
        employeeData.contract_to_be_updated.final = null;
      }
    } else {
      employeeData.contract_to_be_updated = null;
    }
  }

  initDocumentTagsConfig() {
    return {
      project_tag_selected: this.project_tags[0].value,
      component_tag_selected: undefined
    };
  }

  generateEVGDocument(employee_id, calc_id) {
    this.administrationService.createEVGContractDocument(employee_id, calc_id).then(
      (res) => {
        this.$window.open(res.response, '_blank');
      }).catch((err) => {
        this.NotificationService.error('Fehlgeschlagen');
      });
  }

  initNeoComponentCategories () {
    let categories = {};

    NEO_COMPONENTS_WITH_DOCUMENTS.forEach((component) => {
      categories[component.id] = {
        title: component.display,
        documents: []
      };
    });

    return categories;
  }

  setEmployeeCockpitFiles (employee, files) {
    this.all_files = files;
    employee.neo_document_categories = this.initNeoComponentCategories();

    employee.hasUploadedNeoDocuments = false;
    employee.neoDocuments = files.filter((file) => {
      if (file.meta.tags && (file.meta.tags.indexOf( 'neo' ) !== -1 || file.meta.tags.indexOf( 'evg_final' ) !== -1) && (-1 == file.meta.tags.indexOf(DEFAULT_YEARLY_DOCUMENTS_TAG_STRING) && !this.fileService.isAuditTrail(file.meta.tags))) {
        let category_set = false;
        Object.keys(employee.neo_document_categories).forEach((category) => {
          if (file.meta.tags.includes(category)) {
            employee.neo_document_categories[category].documents.push(file);
            category_set = true;
            employee.hasUploadedNeoDocuments = true;
          }
        });

        if (category_set === false) {
          employee.neo_document_categories.other.documents.push(file);
          employee.hasUploadedNeoDocuments = true;
        }
      }
      return file;
    });

    employee.salaryDocuments = files.filter(file => file.meta.tags && (-1 !== file.meta.tags.indexOf('salaryStatement') && !this.fileService.isAuditTrail(file.meta.tags)));
    employee.bgmDocuments = files.filter(file => file.meta.tags && (-1 !== file.meta.tags.indexOf('bgmCertificate') && !this.fileService.isAuditTrail(file.meta.tags)));
    employee.yearlyDocuments = files.filter(file =>
      file.meta.tags &&
      (-1 !== file.meta.tags.indexOf(DEFAULT_YEARLY_DOCUMENTS_TAG_STRING)) &&
      !this.fileService.isAuditTrail(file.meta.tags)
    );
    employee.calculationDocuments = files.filter(file => file.meta.tags && (-1 !== file.meta.tags.indexOf('calculation') && !this.fileService.isAuditTrail(file.meta.tags)));
    employee.support_request_files = files.filter((file) => {
      if (file.meta.tags && (-1 !== file.meta.tags.indexOf(SUPPORT_REQUEST_TAG.main_tag))) {
        file.filename_formatted = this.FilenameService.getFileName(file);
        return true;
      }
      return false;
    });

    employee.insuranceDocuments = files.filter((file) => {
      if (file.meta.tags && (file.meta.tags.indexOf('insuranceDocument') > -1)) {
        file.filename_formatted = this.FilenameService.getFileName(file);
        return true;
      }
      return false;
    });

  }

  expirationDateHandler(expirationDate) {
    const expirationDateObj = this.moment(expirationDate, 'DD.MM.YYYY');

    if (expirationDateObj.isSameOrBefore(this.moment())) {
      this.expirationDateInvalidError = 'Das Datum muss in der Zukunft liegen.';
    } else {
      this.expirationDateInvalidError = '';
    }
  }

  upload(employee, employeeData, form) {
    let tags = [employee.documents_metadata.project_tag_selected];
    if (employee.documents_metadata.project_tag_selected !== 'salaryStatement') {
      tags.push(employee.documents_metadata.component_tag_selected);
    }

    let promises = [];

    employee.uploading = true;
    let extra_payload = null;

    if (employee.documents_metadata.request_digital_signing) {

      tags.push(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BO_REQUEST);

      let digitalSigninRequest = new DigitalSigningRequest(
        this.Made.user.valuenet_id,
        employee.documents_metadata.expirationDate,
        {
          type: employee.documents_metadata.project_tag_selected,
          subtype: employee.documents_metadata.component_tag_selected
        },
        employee.documents_metadata.ticket_number,
        employee.documents_metadata.digital_signing_comment,
        {
          employeeId: employeeData.id,
          customerId: employeeData.customer_id
        },
        employee.documents_metadata.digital_signing_coordinates,
        RequestState.AwaitingForSigning,
        null
      )

      extra_payload = this.BackendFileUploadMapper.toApi(digitalSigninRequest);
    }

    promises.push(
      this.Made.upload(
        employee.files[0],
        tags,
        employee._id,
        extra_payload,
      )
    );

    this.$q
      .all(promises)
      .then(async () => {
        if (employee.documents_metadata.request_digital_signing) {
          let email_data = angular.copy(extra_payload);
          email_data.filename = employee.files[0].name;
          await this.mailService.sendBOFileRequestInitEmail(employeeData.id, email_data, employee.documents_metadata.ticket_number);
        }
        employee.files = [];
        employee.documents_metadata.digital_signing_comment = DIGITAL_SIGNING_DEFAULT_COMMENT;
        employee.documents_metadata.project_tag_selected = null;
        employee.documents_metadata.component_tag_selected = null;
        employee.documents_metadata.ticket_number = null;
        form.$setPristine();
        return;
      })
      .finally()
      .then(() => this.userService.getUploadedFiles(employee._id))
      .then(files => this.setEmployeeCockpitFiles(employee, files))
      .finally(() => employee.uploading = false);
  }

  async openMatchingYouSignAuditTrail(document) {
    let procedure_id_tag = await  this.fileService.getProcedureIDFileTag(document);
    await this.fileService.openMatchingYouSignAuditTrail(procedure_id_tag, this.all_files);
  }

  deleteDocument (employee, document, fileName = null) {
    fileName = fileName || document.filename;

    this.dialogService
      .frageDialog(`Sie möchten das Dokument "${fileName}" wirklich löschen?`, '', {
        approve: 'Bestätigen',
        decline: 'Abbrechen'
      })
      .then(
        () => {
          this.userService.deleteFiles([document._id]);

          this.userService.getUploadedFiles(employee._id).then(files => {
            this.setEmployeeCockpitFiles(employee, files);
          });
        },
        () => {
        }
      );
  }

  deleteAllDocuments (employee, documents) {
    this.dialogService
      .frageDialog(`Sie möchten ${documents.length} Dateien löschen?`, '', {
        approve: 'Bestätigen',
        decline: 'Abbrechen'
      })
      .then(
        () => {
          let fileIds = [];
          for (var document of documents) {
            fileIds.push(document._id);
          }
          this.userService.deleteFiles(fileIds);

          this.userService.getUploadedFiles(employee._id).then(files => {
            this.setEmployeeCockpitFiles(employee, files);
          });
        },
        () => {
        }
      );
  }

  getCheckoutDocumentFilename (document) {
    let createdDate = new Date(document.meta.created * 1000),
      day = (createdDate.getDate() >= 10 ? '' : '0') + createdDate.getDate(),
      month = (createdDate.getMonth() >= 9 ? '' : '0') + (createdDate.getMonth() + 1),
      year = createdDate.getFullYear(),
      hours = (createdDate.getHours() >= 10 ? '' : '0') + createdDate.getHours(),
      minutes = (createdDate.getMinutes() >= 10 ? '' : '0') + createdDate.getMinutes();

    return `${this.DocumentConstants[document.meta.tags[1]].name}_${day}.${month}.${year}_${hours}.${minutes}`;
  }

  getNeoDocumentFilename(document) {
    if(document.meta.tags.includes( DEFAULT_YEARLY_DOCUMENTS_TAG_STRING )) {
      let filename =  `jahresabfrage_${this.moment().year()}_${document.filename}`;
      return filename;
    }
    return document.filename;
  }

  parseStatus (employee) {
    const is_existing = !!(employee.id && employee._id);
    // const has_email = !!(employee.access_status && employee.access_status.emails && employee.access_status.emails.length > 0);
    const has_email = is_existing && employee.access_status.emails && employee.access_status.emails.length > 0 && employee.access_status.emails[0] !== '';
    // const is_invited = (employee.access_status && employee.cidaas_status.invitation_exists);
    const is_invited = is_existing && employee.cidaas_status.invitation_exists;
    const has_logged_in = !!(employee.access_status && employee.access_status.lastLogin && employee.access_status.lastLogin !== null);
    const is_active = employee.access_status && employee.access_status.active;
    const has_cidaas_sub = employee.cidaas_status.sub_exists

    return this.userService.getUserStatusColor(is_existing, has_email, is_invited, has_logged_in, is_active, has_cidaas_sub);
  }

  showStatusLog(employee) {
    this.dialogService.openStatusLog(employee);
  }

  migrateEmployersToACL() {
    this.ACLService.migrateCurrentEmployersToACL()
      .then((res) => {
        console.log(res);
      });
  }

  getACLUserGroups() {
    return this.ACLService.getACLUserGroups({to_include_approval: true})
      .then((customer_specific_groups) => {
        return this.CUSTOMER_SPECIFIC_ROLES_BY_NAME = customer_specific_groups.reduce((acc, user) => {
          acc[user['name']] = user;
          return acc;
        }, {});
      });
  }


  openEmployeeStateDialogue(employee_id, $event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.dialogService.ngDialog.openConfirm({
      template: change_employee_state_dialog_html(),
      plain: true,
      controller: 'ChangeEmployeeStateDialogueController',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      data: {
        'employee_id': employee_id
      },
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    })
      .then( () => {
        this.filterEmployees();
      });
  }

  employeeInUnpaidLeave(state) {
    return state === this.VSP_CONSTANTS.EMPLOYEE_STATE_BY_NAME['unpaid_leave']['name'];
  }

  employeeLeftCompany(state) {
    return state === this.VSP_CONSTANTS.EMPLOYEE_STATE_BY_NAME['terminated_employment']['name'];
  }

  employeeInPaidLeave(state) {
    return state === this.VSP_CONSTANTS.EMPLOYEE_STATE_BY_NAME['paid_leave']['name'];
  }

  employeeInSickLeave(state) {
    return state === this.VSP_CONSTANTS.EMPLOYEE_STATE_BY_NAME['sick_leave']['name'];
  }

  initFileSubtypes() {
    let neo_subtypes = NEO_COMPONENTS_WITH_DOCUMENTS.map((component) => {
      return {
        name: component.display,
        value: component.id,
        type: 'neo'
      };
    });

    let bonus_subtypes = BONUS_COMPONENTS_WITH_DOCUMENTS.map((component) => {
      return {
        name: component.display,
        value: component.id,
        type: 'bonus'
      };
    });

    let file_subtypes_by_main_type = {
      'neo': neo_subtypes,
      'bonus': bonus_subtypes
    };

    return file_subtypes_by_main_type;
  }

  getBoRequestTypeDisplay(fileType) {
    let type = this.lodash.get(this.PROJECT_TAGS_BY_NAME, fileType.type)

    if (!type) {
      return '-'
    }

    let subtype = null;

    if (type.value === 'neo') {
      subtype = COMPONENTS_WITH_DOCUMENTS_BY_ID[fileType.subtype].display;
    } else if (type.value === 'bonus') {
      subtype = BONUS_COMPONENTS_WITH_DOCUMENTS_BY_ID[fileType.subtype].display;
    }

    return subtype ? (type.name + ' - ' + subtype) : type.name;
  }

  isNeoContractDigitalRequest(fileType) {
    if (fileType && fileType.type == 'neo' && fileType.subtype == 'evg_final') {
      return true;
    }

    return false;
  }

  openPcLeasingBlockConfirmationDialog(employeeData) {
    const data = this.forms.pcLeasingBlock[employeeData.id];
    const shouldBlock = data.isBlocked;
    const blockingReason = !shouldBlock ? '' : data.blockingReason

    this.MatDialogService.open(
      TogglePcLeaseBlockingDialogComponent,
      {
        data: {
          employee: employeeData,
          shouldBlock: shouldBlock,
          blockingReason: blockingReason
        },
        width: 800
      }
    )
    //   .afterClosed()
    // .subscribe(() => {
    //   this.loadPcLeaseBlockingData(employeeData.id);
    // });
  }

  canMakeTogglePcLeasingBlock(employeeId) {
    const formData = this.forms.pcLeasingBlock[employeeId];

    if(!formData.isBlock) {
      return true
    }

    if(formData.isBlock) {
      if(!formData.blockingReason) {
        return false
      }
      if(formData.blockingReason.length) {
        return true
      }
    }

    return false;
  }

  loadPcLeaseBlockingData(employeeId) {
    return this.employeeService.getEmployeeBlockedPcComponentsLog(employeeId)
    .then((pcLog) => {
      let isBlocked = false;
      let blockingReason = '';

      if(pcLog) {
        isBlocked = pcLog.is_blocked;
        blockingReason = !isBlocked ? '' : pcLog.blocking_reason;
      }

      if(isBlocked) {
        this.pcBlockedEmployeesInitList.push(employeeId);
      }
      this.forms.pcLeasingBlock[employeeId].isBlocked = isBlocked;
      this.forms.pcLeasingBlock[employeeId].blockingReason = blockingReason;
    })
  }

  isPcBlockedForEmployee(employeeId) {
    if(!this.forms.pcLeasingBlock[employeeId]) {
      return this.pcBlockedEmployeesInitList.includes(employeeId);
    }

    return this.forms.pcLeasingBlock[employeeId].isBlocked;
  }

  purgeCidaasUser(employeeId) {
    this.dialogService
      .question('Wollen Sie diesen Nutzer wirklich aus Cidaas löschen?')
      .then(() => {
        this.loading = true;
        this.userService.purgeCidaasUser(employeeId)
          .then(() => {
            this.NotificationService.message('Der Cidaas Nutzer wurde erfolgreich gelöscht.');
            this.filterEmployees();
          }).catch(() => {
            this.NotificationService.error('Fehlgeschlagen');
        });
      })
      .finally(() => this.loading = false);
  }

  activateUser(employeeId) {
    this.dialogService
      .question('Are you sure you want to activate this user?')
      .then(() => {
        this.loading = true;
        this.userService.activateUser(employeeId)
          .then(() => {
            this.NotificationService.message('Der Nutzer wurde erfolgreich aktiviert');
            this.filterEmployees();
          }).catch(() => {
            this.NotificationService.error('Fehlgeschlagen');
        });
      })
      .finally(() => this.loading = false);
  }

  goToCidaasAdminPortal(cidaas_sub) {
    this.employeeService.goToCidaasAdminPortal(cidaas_sub);
  }
}

EmployeeListController.$inject = $inject;
