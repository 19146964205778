import { BonusDigitalSigningComponent } from "./components/bonus-component";
import { PcDigitalSigningComponent } from "./components/pc-component";
import {BaseDigitalSigningComponent} from "./components/base-component";

export const DIGITAL_SIGNING_COORDINATES_REGEX = '^(\\d{1,3}),(\\d{1,3})$';
export const DIGITAL_SIGNING_DEFAULT_COORDINATES = '174,266';
export const DIGITAL_SIGNING_DEFAULT_COMMENT = 'Ihre Unterlagen wurden für die digitale Unterschrift vorbereitet.';

const $inject = [
  '$q',
  'Made',
  'moment',
  'lodash',
  'VSP_CONSTANTS',
  'API_END_POINTS'
]
export default class DigitalSigningService {

  constructor(
    $q,
    Made,
    moment,
    lodash,
    VSP_CONSTANTS,
    API_END_POINTS,
  ) {

    Object.assign(this, {
      $q,
      Made,
      moment,
      lodash,
      VSP_CONSTANTS,
      API_END_POINTS
    });

    this.defaultComponentsFactory = {
      [this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BONUS]: () => new BonusDigitalSigningComponent(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BONUS),
      [this.VSP_CONSTANTS.DIGITAL_SIGNITURE.PC]: () => new PcDigitalSigningComponent(this.VSP_CONSTANTS.DIGITAL_SIGNITURE.PC),
      [this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING]: () => new BaseDigitalSigningComponent('', '', '', true, true, true),
      [this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BO_REQUEST]: () => new BaseDigitalSigningComponent('', '', '', true, true, true),
      [this.VSP_CONSTANTS.DIGITAL_SIGNITURE.DTICKET]: () => new BaseDigitalSigningComponent('', '', '', true, false, true)
    };

  }

  canSignDigitally(options) {
    return this.Made.request(this.API_END_POINTS.YOUSIGN.CAN_USE_DS, options);
  }

  getEnabledComponentsConfigByType(config, type) {

    if (!this.defaultComponentsFactory[type]) {
      throw "Given type is invalid.";
    }

    let configuration = this.lodash.get(config, `digital_signiture_config.enabled_components.${type}`, null);
    if (configuration) {
      return configuration;
    }

    let checkTypes = [
      this.VSP_CONSTANTS.DIGITAL_SIGNITURE.BONUS,
      this.VSP_CONSTANTS.DIGITAL_SIGNITURE.PC,
      this.VSP_CONSTANTS.DIGITAL_SIGNITURE.CAR_LEASING,
      this.VSP_CONSTANTS.DIGITAL_SIGNITURE.DTICKET
    ]
    return this.defaultComponentsFactory[type]().toMongo()[checkTypes.includes(type) ? type : ''];

  }

  signDocumentDigitally ($params) {
    return this.Made.request(this.API_END_POINTS.YOUSIGN.START_DIGITAL_SIGNITURE_PROCESS, $params);
  }

  getRequestsForSigning(employee_id) {
    return this.Made.request(this.API_END_POINTS.YOUSIGN.BO_REQUESTED_FILES, {
      employee_id: employee_id
    });
  }

  cancelBoDigitalSigningRequest(fileId, comment) {
    return this.Made.request('rpc://utility/digitalsigniture/cancel_bo_request_file', {
      file_id: fileId,
      comment: comment
    });
  }

  updateYouSignRequest(procedureId, update) {
    return this.Made.request(this.API_END_POINTS.YOUSIGN.UPDATE_YOUSIGN_REQUEST, {
      criteria: {'procedure.id': procedureId, 'status': 'requested'},
      update: {'status': 'pending'}
    });
  }

}

DigitalSigningService.$inject = $inject;
