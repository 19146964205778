import {Inject, Injectable} from '@angular/core';

type CarLeasingSignatureType = 'car_leasing_pre_contract' | 'car_leasing';



@Injectable({
  providedIn: 'root'
})
export class DigitalSignatureYouSignServiceV3 {

  private madeRpcMethod: string = "rpc://utility/digitalsigniture/v3/yousign";

  constructor(
    @Inject('Made') private made: any,
  ) { }

  _makeCall(endpoint: string, data: object|null=null) {
    if (data)
      return this.made.request(this.madeRpcMethod + endpoint, data);
    else
      return this.made.request(this.madeRpcMethod + endpoint);
  }

  // @TODO -> rename properly method and parameters
  initiateCarLeasing(employeeId: number, documentUrl: string|null, carLeasingBasketId: string, signatureType: CarLeasingSignatureType) {
    return this._makeCall('/initiate_signature_request', {
      employee_id: employeeId,
      document_url: documentUrl,
      car_leasing_basket_id: carLeasingBasketId,
      signature_type: signatureType
    });
  }

  openYouSignFileInNewTab(digitalSignatureRequestId: string, vnDocType: 'audit_trail'|'signed') {
    return this._makeCall('/download_yousign_file', {
      yousign_request_id: digitalSignatureRequestId,
      vn_doc_type: vnDocType
    }).then((response: any) => {
          const file_content_base64 = response.file_content_base64;
          const filename = response.filename;

          // Decode Base64 and create Blob
          const byteCharacters = atob(file_content_base64);
          const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Create Object URL and open in new tab
          const url = window.URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');
          if (!newTab) {
            alert('Pop-up blocked. Please allow pop-ups to view the file.');
          }

          // Revoke Object URL after use
          setTimeout(() => window.URL.revokeObjectURL(url), 5000);
    });
  }
  getCarLeasingDocuments(employeeId: number) {
    return this._makeCall('/get_car_leasing_files', {
      employee_id: employeeId
    });
  }

  getCarLeasingDocumentsForMeineAkte(employeeId: number, callback: any=null) {
    return this.getCarLeasingDocuments(employeeId).then((files: any[]) => {
      files.forEach(file => {
        let date = new Date(file.metadata[1] * 1000);
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');

        // Format the date as Y-m-d/H-M-S
        let formattedDate = `${year}-${month}-${day}/${hours}-${minutes}-${seconds}`;
        file.yousign_v3 = true;
        file.filename = '%_' + formattedDate;
        if (file.process_type == 'pre-contract') {
          file.filename = file.filename.replace('%', 'car_leasing_pre_contract_contract');
        } else if (file.process_type == 'digital') {
          file.filename = file.filename.replace('%', 'car_leasing_contract_contract');
        }

        if (callback) {
          callback(file);
        }

      })
      return files
    });
  }

  //@deprecated
  getPcLeasingBasketFiles(employeeId: number) {
    return this._makeCall('/get_pc_leasing_basket_files', {
      employee_id: employeeId
    })
  }
  //@deprecated
  getDTicketFiles(employeeId: number) {
    return this._makeCall('/get_dticket_files', {
      employee_id: employeeId
    })
  }

  //@deprecated
  getDigitalSignatureByMongoID(mongoResourceId: string) {
    return this._makeCall('/get_finalised_digital_signature_by_mongo_id', {
      mongo_db_resource_id: mongoResourceId
    })
  }
  //@deprecated
  getBonusFiles(employeeId: number) {
    return this._makeCall('/get_bonus_files', {
      employee_id: employeeId
    })
  }
  //@deprecated
  getBORequestFiles(employeeId: number) {
    return this._makeCall('/get_bo_request_files', {
      employee_id: employeeId
    })
  }

  testWebhook(youSignRequestId: string) {
    return this._makeCall('/test_yousign_v3_webhook', {
      yousign_request_id: youSignRequestId
    });
  }
}
