var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};































































pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};
pug_mixins["select"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.attributes) {
attributes.attributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "form-group"},attributes.groupAttributes]), true)) + "\u003E\u003Clabel" + (" class=\"control-label\""+pug.attr("for", attributes.key, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E\u003Cselect" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"name": pug.escape(id),"id": pug.escape(id),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"disabled": pug.escape(attributes.disabled),"required": pug.escape(attributes.required),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
if (attributes.titleMap) {
// iterate attributes.titleMap
;(function(){
  var $$obj = attributes.titleMap;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var option = $$obj[pug_index3];
if ('object' === typeof option) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option.value, false, true)) + "\u003E" + (pug.escape(null == (pug_interp = option.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = option) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

}
block && block();
pug_html = pug_html + "\u003C\u002Fselect\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Cdiv overlay=\"employeeEditController.loading.main\"\u003E\u003Ch1 ng-if=\"ngDialogData.employeeId &amp;&amp; !employeeEditController.iAmEmployer\"\u003EArbeitnehmer jetzt bearbeiten\u003C\u002Fh1\u003E\u003Ch1 ng-if=\"!ngDialogData.employeeId&amp;&amp; !employeeEditController.iAmEmployer\"\u003EArbeitnehmer anlegen\u003C\u002Fh1\u003E\u003Ch1 ng-if=\"employeeEditController.iAmEmployer\"\u003EMitarbeiterdaten\u003C\u002Fh1\u003E\u003Cp ng-if=\"employeeEditController.iAmEmployer\"\u003EEine Bearbeitung ist hier nicht möglich.\u003C\u002Fp\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv overlay=\"employeeEditController.working\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-4\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "employeeEditController.employee.sex","title": "Anrede","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.selectEmployeeSex","titleMap": [{"value":"1","name":"Herr"},{"value":"0","name":"Frau"}],"required": pug.escape(true),"validationMessages": {"required":"Geben Sie ein Geschlecht an"},"attributes": {"vn-number-to-bool":true,"vn-autofocus":true,"tabindex":1}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns\" ng-class=\"{'medium-6': employeeEditController.iAmSpecialAdvisor, 'medium-8': !employeeEditController.iAmSpecialAdvisor}\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.employeenumber","id": "employeeEditController.employee.employeenumber","title": "Personalnummer","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputPersonalNumber","required": pug.escape(true),"validationMessages": {"required":"{{((employeeEditController.employee.testclient || employeeEditController.employee.status === 1) && 'Bitte geben Sie Ihre Personalnummer ein') || employeeEditController.getPatternForEmployer().description}}","unique":"Diese Personalnummer wird bereits verwendet","pattern":"{{employeeEditController.getPatternForEmployer().description}}"},"attributes": {"ng-pattern":"employeeEditController.getPatternForEmployer().syntax","ng-change":"employeeEditController.resetUniqueEmployeeId()","tabindex":2}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-2\" ng-if=\"employeeEditController.iAmSpecialAdvisor\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "employeeEditController.employee.status","title": "Temporär","ng-true-value": 1,"data-cy": "vn.cockpit.registration.advisorRegistrationDialog.checkTemporaryUser","ng-false-value": 0,"ng-change": "employeeEditController.handleTestEntitySwitch()"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.lastname","title": "Nachname","required": pug.escape(true),"data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputLastName","validationMessages": {"required":"Geben Sie einen Nachnamen an"},"attributes": {"tabindex":3}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.firstname","title": "Vorname","required": pug.escape(true),"data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputFirstName","validationMessages": {"required":"Geben Sie einen Vornamen an"},"attributes": {"tabindex":4}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.kostenstelle","title": "{{employeeEditController.getOrganizationConfigForEmployer().cost_center.label}}","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputAccountingUnit","validationMessages": {"required":"Bitte {{employeeEditController.getOrganizationConfigForEmployer().cost_center.label}} hier eintragen."},"attributes": {"tabindex":5,"ng-required":"employeeEditController.getOrganizationConfigForEmployer().cost_center.is_required"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.org_einheit","title": "{{employeeEditController.getOrganizationConfigForEmployer().organization.label}}","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputCompanyDepartment","validationMessages": {"required":"Bitte {{employeeEditController.getOrganizationConfigForEmployer().organization.label}} hier eintragen."},"attributes": {"tabindex":6,"ng-required":"employeeEditController.getOrganizationConfigForEmployer().organization.is_required"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"birthdateInput\" date-refocus=\"true\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.birthdate","title": "Geburtsdatum","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputEmployeeBirthDate","attributes": {"vn-date":"true","vn-date-reformat":"true","vn-date-string-model":"true","vn-date-year-range":"true","vn-date-age-min":14,"vn-date-age-max":90,"ng-model-options":"{allowInvalid: true}","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","tabindex":7},"fieldHtmlClass": "birthdateInput","validationMessages": {"required":"Bitte Geburtsdatum angeben","parse":"Bitte Geburtsdatum im Format tt.mm.jjjj eingeben","age":"Bitte überprüfen Sie die Eingabe"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E\u003Cdatepicker date-format=\"dd.MM.yyyy\" selector=\"entrydateInput\" date-refocus=\"false\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.entrydate","title": "Eintrittsdatum","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputCompanyEntryDate","attributes": {"vn-date-helper":"true","ng-model-options":"{allowInvalid: true}","ng-pattern":"\u002F^(0?[1-9]|[12][0-9]|3[01])\\.([0]?[0-9]|1[0-2])\\.\\d{4}$\u002F","tabindex":8},"fieldHtmlClass": "entrydateInput","validationMessages": {"required":"Bitte Eintrittsdatum angeben","pattern":"Bitte Eintrittsdatum im Format tt.mm.jjjj eingeben"}}
});
pug_html = pug_html + "\u003C\u002Fdatepicker\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.street","title": "Straße","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputEmployeeStreet","readonly": "employeeEditController.employee.block_address","attributes": {"tabindex":9}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.zip","title": "Postleitzahl","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputEmployeePostcode","readonly": "employeeEditController.employee.block_address","attributes": {"tabindex":10}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns medium-6\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "employeeEditController.employee.city","title": "Stadt","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputEmployeeCity","readonly": "employeeEditController.employee.block_address","attributes": {"tabindex":11}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "employeeEditController.employee.loc_id","title": "Standort","required": pug.escape(true),"data-cy": "vn.cockpit.registration.advisorRegistrationDialog.selectCustomerLocation","validationMessages": {"required":"Wählen Sie einen Standort aus"},"readonly": "!employeeEditController.locations.length","attributes": {"ng-options":"location.value as location.name for location in employeeEditController.locations","tabindex":13}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E";
pug_mixins["select"].call({
attributes: {"key": "employeeEditController.neoProjectId","title": "Neo-Projekt","required": pug.escape(true),"data-cy": "vn.cockpit.registration.advisorRegistrationDialog.selectNeoProject","validationMessages": {"required":"Wählen Sie einen Neo-Projekt aus"},"attributes": {"ng-options":"project.value as project.name for project in employeeEditController.neoProjectList","tabindex":14}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"key": "employeeEditController.employee.testclient","title": "Testmitarbeiter","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.checkTestEmployee","labelHtmlClass": "switchbox-float-label","ng-change": "employeeEditController.handleTestEntitySwitch()"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"employeeEditController.isCidaasUser()\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "email","key": "employeeEditController.employee.login.email","title": "Login E-Mail Preview","disabled": pug.escape(true)}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"!employeeEditController.isCidaasUser()\"\u003E\u003Cdiv ng-if=\"!employeeEditController.iAmSpecialAdvisor &amp;&amp; !employeeEditController.iAmAdvisor \" overlay=\"employeeEditController.loading.checking_mail\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "email","key": "employeeEditController.employee.login.email","title": "Login E-Mail","disabled": pug.escape(true),"data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputEmployeeEmail","type": "email","validationMessages": {"required":"Bitte geben Sie eine E-Mail Adresse an","email":"Bitte geben Sie eine gültige E-Mail Adresse an","domain":"{{employeeEditController.getDomainErrorMessage()}}"},"attributes": {"tabindex":15,"ng-change":"employeeEditController.handleEmailInputChange()","ng-model-options":"{ debounce: 300 }"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"employeeEditController.iAmSpecialAdvisor || employeeEditController.iAmAdvisor \" overlay=\"employeeEditController.loading.checking_mail\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-11\"\u003E";
pug_mixins["input"].call({
attributes: {"id": "email","key": "employeeEditController.employee.login.email","title": "Login E-Mail","data-cy": "vn.cockpit.registration.advisorRegistrationDialog.inputEmployeeEmail","type": "email","required": pug.escape(true),"validationMessages": {"domain":"{{employeeEditController.getDomainErrorMessage()}}","required":"Bitte geben Sie eine E-Mail Adresse an","email":"Bitte geben Sie eine gültige E-Mail Adresse an","unique":"Bitte überprüfen Sie die eingegebene E-Mail-Adresse"},"attributes": {"tabindex":16,"ng-change":"employeeEditController.handleEmailInputChange()","ng-model-options":"{ debounce: 300 }"}}
});
pug_html = pug_html + "\u003Cp class=\"helpText\" ng-show=\"employeeEditController.checked\"\u003E\u003Cdiv class=\"red\" ng-if=\"employeeEditController.isEmailTaken()\"\u003E\u003Cspan\u003EUser: {{ employeeEditController.check.user.id }}, {{ employeeEditController.check.user.firstname }} {{ employeeEditController.check.user.lastname }}\u003C\u002Fspan\u003E\u003Cbr\u003E\u003Cspan\u003EFirma: {{ employeeEditController.check.user.employer_address.customer_id }}, {{ employeeEditController.check.user.employer_address.name }}\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"green\" ng-if=\"!employeeEditController.isEmailTaken()\"\u003E\u003Cspan\u003EKein User zu der eingegebenen E-Mail Adresse gefunden.\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"red\" ng-if=\"employeeEditController.email.checked &amp;&amp; !employeeEditController.email.valid\"\u003E\u003Cspan\u003EValidierung der E-Mail-Adresse ist fehlgeschlagen\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E\u003Cbutton class=\"button button-primary validator\" type=\"button\" data-cy=\"vn.cockpit.registration.advisorRegistrationDialog.buttonVerifyEmail\" ng-click=\"employeeEditController.checkEmailValidity()\"\u003EPrüfen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cspan class=\"error\" ng-show=\"employeeEditController.error\"\u003EIhre Eingaben konnten nicht gespeichert werden.\u003C\u002Fspan\u003E\u003Cbutton class=\"button button-primary\" type=\"submit\" value=\"Speichern\" data-cy=\"vn.cockpit.registration.advisorRegistrationDialog.buttonSubmit\" ng-click=\"employeeEditController.save()\" ng-disabled=\"!employeeEditController.canSave()\" ng-if=\"!employeeEditController.iAmEmployer\"\u003ESpeichern\u003C\u002Fbutton\u003E";
},
attributes: {"class": "form-padding","name": "employeeEditController.forms.employeeEditForm"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;