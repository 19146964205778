import { CarLeasingBasket } from "../../../../app/car-leasing/models/car-leasing-basket.model";

const $inject = [
  '$scope',
  '$state',
  '$timeout',
  'CarLeasingApiService',
  'dialogService',
  'fileService',
  'employeeService',
  'MatDialogService',
  'customerService',
]

import car_leasing_ticket_dialog_html from '../../../dialogs/car-leasing/ticket/index.pug'
import car_leasing_cancellation_dialog_html from '../../../dialogs/car-leasing/cancellation/index.pug'
import car_leasing_first_check_dialog_html from '../../../dialogs/car-leasing/first-check/index.pug'
import car_leasing_control_process_dialog_html from '../../../dialogs/car-leasing/contract-process/index.pug'
import {
  CarLeasingFinDataDialogComponent
} from "../../../../app/car-leasing/dialogs/car-leasing-fin-data-dialog/car-leasing-fin-data-dialog.component";
import {
  CAR_LEASING_APPROVAL_STATES,
  CAR_LEASING_CONTRACT_PROCESS_STATES,
  CAR_LEASING_FIRST_CHECK_STATES,
  CAR_LEASING_IS_CANCELLED
} from '../../../../app/car-leasing/services/car-leasing-api.service';


export default class AdministrationCarLeasingController {
  constructor(
    $scope,
    $state,
    $timeout,
    CarLeasingApiService,
    dialogService,
    fileService,
    employeeService,
    MatDialogService,
    customerService,
  ) {
    Object.assign(this, {
      $scope,
      $state,
      $timeout,
      CarLeasingApiService,
      dialogService,
      fileService,
      employeeService,
      MatDialogService,
      customerService,
    });

    this.loading = {
      main: false,
      search: false,
      finnRequest: false
    };

    this.auditTrails = {};

    this.carBaskets = [];

    this.filters = {
      'employeeId': this.$state.params.employeeId ? parseInt(this.$state.params.employeeId) : null,
      'customerId': null,
      'finnOrderId': null,
      'preferredDeliveryDate': null,
      'orderStates': {
        selected: [],
        options: [].concat(
          CAR_LEASING_APPROVAL_STATES,
          CAR_LEASING_CONTRACT_PROCESS_STATES,
          CAR_LEASING_FIRST_CHECK_STATES,
          CAR_LEASING_IS_CANCELLED
        )
      }
    };

    this.results = 0;

    this.employee = {};

    this.carLeasingOrderStates = {
      selected: [],
      options: [].concat(
        CAR_LEASING_APPROVAL_STATES,
        CAR_LEASING_CONTRACT_PROCESS_STATES,
        CAR_LEASING_FIRST_CHECK_STATES
      )
    }

    this.carLeasingApprovalStates = {
      selected: [],
      options: CAR_LEASING_APPROVAL_STATES.map(item => {
        return {
          id: item.id,
          display: item.display.split('|')[1].trim()
        }
      }),
    }

    if (this.filters['employeeId']) {
      this.search()
    }
  }

  openFile(fileId) {
    this.fileService.openDocument(fileId);
  }

  getFilters() {
    const employeeId = this.filters['employeeId'];
    const customerId = this.filters['customerId'];
    const finnOrderId = this.filters['finnOrderId'];
    const preferredDeliveryDate = this.filters['preferredDeliveryDate'];
    const orderStates = this.filters['orderStates']['selected'];

    return {
      employeeId: !!employeeId ? employeeId : null,
      customerId: !!customerId ? customerId : null,
      finnOrderId: !!finnOrderId ? finnOrderId : null,
      preferredDeliveryDate: !!preferredDeliveryDate ? preferredDeliveryDate : null,
      orderStates: orderStates.length > 0 ? orderStates : null,
    }
  }

  async triggerFinnRequest(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.$timeout(() => {
      this.loading.finnRequest = true;
      this.loading.main = true;
    });

    basket = await this.CarLeasingApiService.sendFinnDataRequest(basket.id);
    if(basket.orderId) {
      this.filters.finnOrderId = basket.orderId
      await this.search();
    }

    this.$timeout(() => {
      this.loading.finnRequest = false;
      this.loading.main = false;
    });
  }

  async search() {
    this.$timeout(() => {
      this.loading.search = true;
    });

    const filters = this.getFilters();

    this.carBaskets = await this.CarLeasingApiService.getBasketsForAdministration(
      filters.employeeId,
      filters.customerId,
      filters.finnOrderId,
      filters.preferredDeliveryDate,
      filters.orderStates,
    );

    this.employee = this.filters.employeeId ?
      await this.employeeService.get(this.filters.employeeId) :
      await this.employeeService.get();

    this.results = this.carBaskets.length;

    this.$timeout(() => {
      this.loading.search = false;
    });
  }

  goToNEO(employeeId) {
    this.employeeService.goTo({
      'route': 'inApp.administration.neo',
      'params': {employeeId: employeeId}
    });
  }

  async triggerSearch(e) {
    if (e && e.keyCode === 13) {
      e.preventDefault();
      await this.search();
    }
  }

  openContractProcessDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: car_leasing_control_process_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'CarLeasingContractProcessDialogController',
      data: {
        basket: basket
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  /**
   * @param {CarLeasingBasket} basket
   * @param $event
   */
  openFirstCheckDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    const {firstname, lastname} = this.employee;
    this.dialogService.ngDialog.openConfirm({
      template: car_leasing_first_check_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'CarLeasingFirstCheckDialogController',
      data: {
        basket: basket,
        employee: {
          firstname,
          lastname
        }
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  /**
   * @param {CarLeasingBasket} basket
   * @returns {boolean}
   */
  canOpenTicketDialog(basket) {
    return !basket.otrs;
  }

  async load(basket) {

    if (!this.auditTrails[basket.id]) {
      this.auditTrails[basket.id] = {
        loaded: false,
        file: [],
      }
    }

    if (!this.auditTrails[basket.id]['loaded']) {
      this.$timeout(() => {
        this.loading.search = true;
      });

      let result = await this.fileService.getCarLeasingAuditTrailFile(basket.id);
      if (result) {
        result.forEach((trail, index) => {
          this.auditTrails[basket.id]['file'][index] = trail;
        });
      } else {
        this.auditTrails[basket.id]['file'] = null;
      }
      this.auditTrails[basket.id]['loaded'] = true;

      this.$timeout(() => {
        this.loading.search = false;
      });
    }
  }

  /**
   * @param {CarLeasingBasket} basket
   * @param $event
   */
  openTicketDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: car_leasing_ticket_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'CarLeasingTicketDialogController',
      data: {
        basket: basket
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  /**
   * @param {CarLeasingBasket} basket
   * @returns {boolean}
   */
   canOpenCancellationDialog(basket) {
    return true;
  }

  openAddFinnDataDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.MatDialogService.open(
      CarLeasingFinDataDialogComponent,
      {
        data: {basketId: basket.id}
      }
    )
      .afterClosed()
      .subscribe((res)=>{
        if (res) {
          this.search()
        }
      });
  }


  /**
   * @param {CarLeasingBasket} basket
   * @param $event
   */
  openCancellationDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: car_leasing_cancellation_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'CarLeasingCancellationDialogController',
      data: {
        basket: basket
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

}

AdministrationCarLeasingController.$inject = $inject
