import {Component, Input, OnInit, ViewEncapsulation, Injector} from '@angular/core';
import {BootstrapService} from "../../../bootstrap.service";


declare var Yousign: any;

@Component({
  selector: 'vn-digital-signature-you-sign',
  templateUrl: './digital-signature-you-sign.component.html',
  styleUrls: ['./digital-signature-you-sign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DigitalSignatureYouSignComponent implements OnInit {

  yousign: any;
  private $state: any;
  @Input() signatureLink!: string;

  constructor(private bootstrapService: BootstrapService, private injector: Injector) {
  }

  ngOnInit(): void {
    this.$state = this.injector.get('$state');
    console.log("this.signatureLink,", this.signatureLink)
    if (typeof Yousign !== 'undefined' && this.signatureLink) {
      this.yousign = new Yousign({
        signatureLink: this.signatureLink,
        iframeContainerId: 'iframe-container',
        isSandbox: false
      });

      //isSandbox: this.bootstrapService.IS_DEV || this.bootstrapService.IS_TEST_ENV || this.bootstrapService.IS_STAGE

      this.yousign.onStarted((data: any) => {
        console.log("Signer has opened the signature");
        console.log(data);
      });

      this.yousign.onSuccess(async (data: any) => {
        console.log("Signer has successfully signed the document");
        console.log(data);
        await this.$state.go('inApp.meineakte.digital_signing_success');
      });

      this.yousign.onError(async (data: any) => {
        console.log("Signer encountered an error when signing");
        console.log(data);
        await this.$state.go('inApp.meineakte.digital_signing_error');
      });

      this.yousign.onPing((data: any) => {
        console.log("Ping - The signature request is loaded");
        console.log(data);
      });

      this.yousign.onDeclined((data: any) => {
        console.log("Declined - The signer declined the signature");
        console.log(data);
      });

      this.yousign.onSignatureDone((data: any) => {
        //You can download the document with all signatures in it
        console.log("Signature Done - The signature request is completed");
        console.log(data);
      });
    }
  }

}
