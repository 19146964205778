import {
  APPROVAL_PROCESS_TYPES_BY_ID,
  APPROVAL_PROCESS_STATES_BY_NAME,
  APPROVAL_PROCESS_TYPES_BY_NAME
} from '../../../services/administrationService';

import decline_approval_process_html from '../../../dialogs/decline_approval_process/decline_approval_process.pug'
import {DticketOrderService} from "../../../../app/dticket-order/services/dticket-order.service";
import {ConfirmationDialogComponent} from "../../../../app/shared/components/confirmation-dialog/confirmation-dialog.component";
import {take} from "rxjs/operators";

const $inject = [
  'BonusService',
  'Made',
  '$timeout',
  'lodash',
  '$scope',
  '$http',
  'authenticationService',
  'administrationService',
  'ACLService',
  'fileService',
  'employeeService',
  'customerService',
  'NotificationService',
  'dialogService',
  'CarLeasingApiService',
  'DticketOrderService',
  'MatDialogService'
];

export default class FidApprovalController {
  constructor(
    BonusService,
    Made,
    $timeout,
    lodash,
    $scope,
    $http,
    authenticationService,
    administrationService,
    ACLService,
    fileService,
    employeeService,
    customerService,
    NotificationService,
    dialogService,
    CarLeasingApiService,
    DticketOrderService,
    MatDialogService
  ) {
    Object.assign(this, {
      BonusService,
      Made,
      $timeout,
      lodash,
      fileService,
      $scope,
      $http,
      authenticationService,
      administrationService,
      ACLService,
      employeeService,
      customerService,
      NotificationService,
      dialogService,
      CarLeasingApiService,
      DticketOrderService,
      MatDialogService
    });

    this.loading = {
      main: false,
      fiducia: false,
      approvals: false,
      approval: {}
    };

    this.APPROVAL_PROCESS_TYPES_BY_ID = APPROVAL_PROCESS_TYPES_BY_ID;
    this.APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES_BY_NAME;

    this.init();
  }

  async init() {
    this.$timeout(() => {
      this.loading.main = true;
    });

    this.forms = {};
    this.neoBikeFiles = {};
    this.bonus_comments = {};
    this.dticketChangeSubsidy = {}
    this.dTicketSubsidyChangeError = ''
    this.workHomeDistanceForApproval = {};
    this.employeeHasCompanyCarForApproval = {};
    this.employeeDataPartialChange = {};
    this.hasCompanyCar = false;
    this.hasCompanyCarOptions = [
      {
        'name': 'Ja',
        'value': true
      },
      {
        'name': 'Nein',
        'value': false
      }
    ]

    await this.initPermisssionTypes();

    await this.getApprovals();

    this.$timeout(() => {
      this.loading.main = false;
    });
  }

  addDefaultEmployeeHasCompanyCar(approval) {
    if (!this.employeeHasCompanyCarForApproval[approval['_id']]) {
      const hasCompanyCar = approval['car_leasing']['checkout_configuration']['has_company_car']

      this.employeeHasCompanyCarForApproval[approval['_id']] = {
        canEdit: false,
        value: hasCompanyCar
      }
    }
  }

  addDefaultApprovalWorkFromDistance(approval) {
    if (!this.workHomeDistanceForApproval[approval['_id']]) {
      const distanceFromHome = approval['car_leasing']['checkout_configuration']['work_home_distance'];

      this.workHomeDistanceForApproval[approval['_id']] = {
        canEdit: false,
        value: distanceFromHome ? distanceFromHome : 1
      }
    }
  }

  addDefaultApprovalEmployeeData(approval) {
    if (!this.employeeDataPartialChange[approval['_id']]) {
      const employee = approval['employee'];

      if (employee) {
        this.employeeDataPartialChange[approval['_id']] = {
          pn: {
            canEdit: false,
            value: employee['employeenumber'],
          },
          cost: {
            canEdit: false,
            value: employee['kostenstelle'],
          },
          organization: {
            canEdit: false,
            value: employee['org_einheit'],
          }
        };
      }
    }
  }

  async initPermisssionTypes() {
    this.$timeout(() => {
      this.loading.permissions_action_config = true;
    });

    let permissions = await this.ACLService.getACLModuleActions('approval');
    this.permission_actions_by_name = permissions.reduce((acc, field) => {
      acc[field.name] = field;
      return acc;
    }, {});

    this.$timeout(() => {
      this.loading.permissions_action_config = false;
    });

  }

  async getOrganizationConfigForEmployer(approval) {
    return this.customerService
      .getConfigurationByCustomerId(approval['employee']['customer_id'])
      .then(customer_configuration => {
        return this.customerService.getOrganizationConfigForEmployer(customer_configuration);
      });
  }

  getDTicketTimestamp(stringDate) {
    return new Date(stringDate).getTime() / 1000;
  }

  async getApprovals() {
    this.$timeout(() => {
      this.loading.approvals = true;
    });

    let approvals = await this.administrationService.getProcessesReadyForApproval();
    let approval_comments = {};
    let loading_approvals = {};
    this.approval_employee_data_model = this.initApprovalEmployeesDataModel(approvals);

    for (let approval of approvals) {
      if (approval['car_leasing']) {
        this.addDefaultApprovalWorkFromDistance(approval);
        this.addDefaultApprovalEmployeeData(approval);
        this.addDefaultEmployeeHasCompanyCar(approval);
      }

      if (approval['dticket']) {
        await this.addticketChangeSubsidy(approval);
      }

      approval_comments[approval['_id']] = {
        comment: ''
      };

      loading_approvals[approval['_id']] = false;
      approval['personal_number_config'] = await this.getCustomerPersonalNumberConfig(approval);

      approval['customer_organization_config'] = await this.getOrganizationConfigForEmployer(approval);
    }

    this.approval_comments = approval_comments;
    this.loading.approval = loading_approvals;
    this.approvals_by_customer = approvals.reduce((acc, approval) => {
      if (!acc[approval.employee.customer_id]) {
        acc[approval.employee.customer_id] = [];
      }
      acc[approval.employee.customer_id].push(approval);
      return acc;
    }, {});

    approvals.sort((ap1, ap2) => {
      return ap1.last_state_change < ap2.last_state_change;
    });

    this.approvals = approvals;

    this.$timeout(() => {
      this.loading.approvals = false;
    });
  }

  async addticketChangeSubsidy(approval) {
    if (!this.dticketChangeSubsidy[approval['_id']]) {
      const dticketConfig = await this.DticketOrderService.getDticketOrderConfig(approval.employee.customer_id);
      const canEdit = dticketConfig.changeOfSubsidy;

      this.dticketChangeSubsidy[approval['_id']] = {
        canEdit,
        value: parseFloat(approval['dticket']['employer_subsidy']),
      }
    }
  }

  initApprovalEmployeesDataModel(approvals) {
    let approval_employee_data_model = {};
    approvals.forEach(approval => {
      approval_employee_data_model[approval['_id']] = {
        employeenumber: {
          value: approval.employee.employeenumber,
          to_update: false,
          id: approval['_id'] + 'employee_number'
        },
        kostenstelle: {
          value: approval.employee.kostenstelle,
          to_update: false,
          id: approval['_id'] + 'kostenstelle'
        },
        org_einheit: {
          value: approval.employee.org_einheit,
          to_update: false,
          id: approval['_id'] + 'org_einheit'
        }
      };
    });

    return approval_employee_data_model;
  }

  async getCustomerPersonalNumberConfig(approval) {
    let customer_configuration = await this.customerService.getConfigurationByCustomerId(approval['employee']['customer_id']);
    return (customer_configuration.personal_number_config && customer_configuration.personal_number_config.is_enabled) ? customer_configuration.personal_number_config : null;
  }

  getApprovalDate(approval) {
    let raw_date = this.lodash.get(approval, this.getApprovalConfig(approval)['settings']['date_key']);
    if (!raw_date) {
      return 'N/A';
    }
    return 'string' === typeof raw_date ? new Date(raw_date) : raw_date * 1000;
  }

  resetUniqueEmployeePersonalNumber(approval, id='employee_number') {
    if (!approval) {
      return
    }

    this.forms['employeeForms'][approval['_id']][`${id}${approval['_id']}`].$setValidity('unique', true);
  }

  resetDTicketUniqueEmployeePersonalNumber(approval) {
    console.log("this.forms['employeeForms']", this.forms['employeeForms']);
    this.resetUniqueEmployeePersonalNumber(approval, 'dticketjobemployee_number')
  }

  getApprovalConfig(approval) {
    return APPROVAL_PROCESS_TYPES_BY_ID[approval['type_id']];
  }

  getApprovalTitle(approval) {
    let title = this.getApprovalConfig(approval)['display'];
    return title;
  }

  getApprovalValueType(approval) {
    let valuetype = this.getApprovalConfig(approval)['value_type'];
    return valuetype;
  }

  openDoc(doc) {
    this.fileService.openDocument(doc._id);
  }

  async approveCarLeasing(approval) {
    const approvalId = approval['_id'];
    const carLeasing = approval['car_leasing'];
    const basketId = carLeasing.id;


    let newHasCompanyCar = null;
    // only report if there is a change to the has_company_car
    if (this.employeeHasCompanyCarForApproval[approval['_id']].canEdit) {
      let newValue = this.employeeHasCompanyCarForApproval[approval['_id']].value;
      let oldValue = carLeasing.checkout_configuration.has_company_car
      if (newValue !== oldValue) {
        newHasCompanyCar = newValue;
      }
    }

    let newDistance = null;
    if (newHasCompanyCar) {
      // distance must be 0 if the BO has changed the has_company_car to True
      newDistance = 0;
    } else {
      // only report if there is a change to the distance
      let newValue = this.workHomeDistanceForApproval[approval['_id']].value;
      let oldValue = carLeasing.checkout_configuration.work_home_distance
      if (newValue !== oldValue) {
        newDistance = newValue;
      }
    }

    let approvalEmployeeDataUpdate = {}

    if (this.employeeDataPartialChange[approvalId]) {

      const {cost, organization, pn} = this.employeeDataPartialChange[approvalId];

      if (cost && cost.canEdit && cost.value) {
        approvalEmployeeDataUpdate['cost'] = cost.value;
      }

      if (organization && organization.canEdit && organization.value) {
        approvalEmployeeDataUpdate['organisation'] = organization.value;
      }

      if (pn && pn.canEdit && pn.value) {
        approvalEmployeeDataUpdate['personalNumber'] = pn.value;
      }
    }

    this.$timeout(() => {
      this.loading.approval[carLeasing.id] = true;
    });

    await this.CarLeasingApiService.approveApproval(
      basketId,
      newDistance,
      newHasCompanyCar,
      carLeasing['employee_id'],
      approvalEmployeeDataUpdate
    );

    await this.getApprovals();
    this.NotificationService.message('Freigegeben');
    this.workHomeDistanceForApproval[approvalId].canEdit = false;

    this.$timeout(() => {
      this.loading.approval[carLeasing.id] = false;
    });

    // reload view
    await this.getApprovals();
  }

  async approval_approve(approval) {
    this.$timeout(() => {
      this.loading.approval[approval['_id']] = true;
    });

    if (this.toUpdateEmployeeData(approval)) {
      let employee = angular.copy(approval['employee']);
      for (let prop in this.approval_employee_data_model[approval['_id']]) {
        employee[prop] = this.approval_employee_data_model[approval['_id']][prop]['value'];
      }

      await this.employeeService.updatePersonalInfoApproval(employee, false)
        .catch((e) => {
          if (e.error && ('8c6f8e2a375bbd1df10cf8c4c547a737' === e.error.id)) {
            this.forms['employeeForms'][approval['_id']][`employee_number${approval['_id']}`].$setValidity('unique', false);
            this.NotificationService.error('Diese Personalnummer wird bereits verwendet');
          } else {
            this.NotificationService.error('Fehlgeschlagen');
          }
          this.loading.approval[approval['_id']] = false;
          throw e;
        });
    }

    await this.administrationService.approvalExternalApprove({approval_id: approval['_id']});

    this.$timeout(() => {
      this.loading.approval[approval['_id']] = false;
    });

    // reload view
    await this.getApprovals();
  }

  async openDeclineDticketDialog(approval) {
    this.MatDialogService.open(ConfirmationDialogComponent, {
      data: {
        title: `EMPLOYEE: ${approval['employee']['id']} Freigabe Ablehnen`,
        withDeclineReason: true
      }
    }).afterClosed()
      .pipe(take(1))
      .subscribe(async result => {
        if (result && result.decision === 'with_reason') {
          await this.DticketOrderService.approverDeclineDticket(approval['_id'], result['data']);
          await this.getApprovals();
        }
      })
  }

  async openConfirmDeclineDialog(approval) {
    this.$timeout(() => {
      this.loading.approval[approval['_id']] = true;
    });

    this.dialogService.ngDialog.openConfirm({
      template: decline_approval_process_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'DeclineApprovalProcessController',
      data: {
        approval_process: approval
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(async () => {
      this.$timeout(() => {
        this.loading.approval[approval['id']] = false;
      });
      this.NotificationService.message('Erfolgreich.')
    }).catch(async (error) => {
      this.$timeout(() => {
        this.loading.approval[approval['id']] = false;
      });
      this.NotificationService.error('Fehler - Es gab ein Problem bei der Ablehnung.')
    }).finally(async () => {
      await this.getApprovals();
    });
  }

  toUpdateEmployeeData(approval) {
    let to_update = false;
    for (let prop in this.approval_employee_data_model[approval['_id']]) {
      if (!angular.equals(this.approval_employee_data_model[approval['_id']][prop]['value'], approval['employee'][prop])) {
        to_update = true;
        break;
      }
    }

    return to_update;
  }

  getBonusComponentName(bonus) {
    return this.BonusService.getBonusComponentName(bonus);
  }

  isPayedByBudget(bonus) {
    if (!bonus || !bonus['checkout_config']) {
      return false;
    }
    return this.BonusService.isPayedByBudget(bonus['checkout_config']);
  }

  getPerMonthValue(bonus) {
    return bonus && bonus['checkout_config'] && bonus['checkout_config']['per_month_value'] ? bonus['checkout_config']['per_month_value'] : '-';
  }

  getPerMonthValueByApproval(approval) {
    return this.lodash.get(approval, this.getApprovalConfig(approval)['settings']['monthly_value_key'], 0)
  }

  async handleEntityFiles(approval) {

    let existInFiles = this.neoBikeFiles.hasOwnProperty(approval['_id']);

    if (approval.type_id !== APPROVAL_PROCESS_TYPES_BY_NAME['neo_bikeleasing']['id'] || existInFiles) {
      return;
    }

    this.$timeout(() => {
      this.loading.approval[approval['_id']] = true;
    });

    this.neoBikeFiles[approval['_id']] = await this.fileService.getBikeleasingApprovalDoc({
      bikeleasing_id: approval.neo_bikeleasing._id
    });

    this.$timeout(() => {
      this.loading.approval[approval['_id']] = false;
    });
  }

  async canApproveProcess(approval) {

    if (!angular.isUndefined(approval.is_allowed_to_approve)) {
      return;
    }

    this.$timeout(() => {
      this.loading.approval[approval['_id']] = true;
    });

    let has_approve_decline_permission = false
    if (approval.car_leasing) {
      has_approve_decline_permission = await this.administrationService.canApproveCarLeasing(approval.car_leasing.customer_id).catch((err) => {
        this.loading.approval[approval['_id']] = true;
      });
    } else if (approval.dticket) {
      has_approve_decline_permission = await this.ACLService.canApproveModule(
        this.Made.user.valuenet_id,
        approval.dticket.customer_id,
        'dticket'
      ).catch((err) => {
        this.loading.approval[approval['_id']] = true;
      });
    } else {
      has_approve_decline_permission = await this.administrationService.canApproveProcess(approval['_id']).catch((err) => {
        this.loading.approval[approval['_id']] = true;
      });
    }

    approval.is_allowed_to_approve = has_approve_decline_permission;

    this.$timeout(() => {
      this.loading.approval[approval['_id']] = false;
    });
  }

  async validateDticketSubsidy(approval) {
    this.$timeout(() => {
      this.loading.approval[approval['_id']] = true;
    });

    if (!this.dticketChangeSubsidy[approval['_id']].value) {
      this.dticketChangeSubsidy[approval['_id']].value = 0
    }

    this.dTicketSubsidyChangeError = null;
    const dticketEmployerSubsidyChanged = this.dticketChangeSubsidy[approval['_id']].value
    const dticketEmployerSubsidy = parseFloat(approval['dticket']['employer_subsidy'])

    try {
      await this.DticketOrderService.approverValidations(
        dticketEmployerSubsidyChanged,
        dticketEmployerSubsidy,
      )
    } catch (e) {
      if (e.error && e.error.message) {
        if (e.error.message === 'DTICKET_LOW_SUBSIDY_ERROR') {
          this.dTicketSubsidyChangeError = 'Der Zuschuss kann lediglich erhöht, jedoch nicht verringert werden.';
        }
        if (e.error.message === 'DTICKET_HIGH_SUBSIDY_ERROR') {
          this.dTicketSubsidyChangeError = 'Der Zuschuss darf maximal 55,10€ betragen.';
        }
      } else {
        this.dTicketSubsidyChangeError = 'Fehlgeschlagen';
      }
    } finally {
      this.$timeout(() => {
        this.loading.approval[approval['_id']] = false;
      });
    }
  }

  async approveDticket(approval) {

    this.$timeout(() => {
      this.loading.approval[approval['_id']] = true;
    });

    if (this.toUpdateEmployeeData(approval)) {
      let employee = angular.copy(approval['employee']);
      for (let prop in this.approval_employee_data_model[approval['_id']]) {
        employee[prop] = this.approval_employee_data_model[approval['_id']][prop]['value'];
      }

      await this.employeeService.updatePersonalInfoApproval(employee, false)
        .catch((e) => {
          if (e.error && ('8c6f8e2a375bbd1df10cf8c4c547a737' === e.error.id)) {
            this.forms['employeeForms'][approval['_id']][`dticketjobemployee_number${approval['_id']}`].$setValidity('unique', false);
            this.NotificationService.error('Diese Personalnummer wird bereits verwendet');
          } else {
            this.NotificationService.error('Fehlgeschlagen');
          }
          this.loading.approval[approval['_id']] = false;
          throw e;
        });
    }

    await this.DticketOrderService.approverApproveDticket(
      approval['_id'],
      this.dticketChangeSubsidy[approval['_id']].value
    )

    await this.getApprovals();

    this.$timeout(() => {
      this.loading.approval[approval['_id']] = false;
    });
  }

  hasEmployeeCompanyCar(approval) {
    if (this.employeeHasCompanyCarForApproval[approval['_id']].canEdit) {
      return this.employeeHasCompanyCarForApproval[approval['_id']].value;
    }

    return approval.car_leasing.checkout_configuration.has_company_car;
  }
}

FidApprovalController.$inject = $inject;
